<template>
    <div class="container-fluid full-section bg-light-to-white">
        <div class="container">
            <div class="row o-hidden full-section">
                <div class="col-xl-9 col-lg-8 col-md-8 order-2 bg-white">
                    <router-view/>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4 order-1 d-sm-flex justify-content-center bg-light">
                    <div class="sidebar desktop pt-2">
                        <ul class="left-menu theme1 mt-0">
                            <li v-for="menuItem in menu" :key="menuItem.link">
                                <template v-if="menuItem.guard">
                                    <router-link :to="menuItem.link">{{ menuItem.label }}</router-link>
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {isDealerGuard} from "@/router/guard";

export default {
    name: "Cabinet",
    data() {
      return {
          menu: [
              { link: '/profile', label: 'Профиль', guard: true },
              { link: '/requests-write-down-points', label: 'Заявки на списание', guard: isDealerGuard() },
              { link: '/product-orders', label: 'Мои заказы', guard: true },
              { link: '/visit-orders', label: 'Мои поездки', guard: true },
              { link: '/points-flow', label: 'Баланс', guard: true },
              { link: '/profile-contacts', label: 'Контакты', guard: true },
          ]
      }
    },
    metaInfo() {
        return {
            title: `Профиль - ЛУКМАРКЕТ`,
        }
    },
}
</script>
<style scoped lang="scss">
.sidebar {
    height: 100%;
    padding-top: 30px;
}

.left-menu {
    list-style: none;
    padding-left: 0;
    margin-top: 25px;
    margin-right: -30px;

    a {
        display: block;
        margin-left: 0;
        padding: 1em;
        font-size: 18px;
        line-height: 1.5;
        text-align: left;
        color: #444;
        transition: .2s ease-in-out;

        @media (max-width: 768px) {
            font-size: 14px;
        }

        &:hover, &:focus, &:active {
            background-color: #eff1f6;
            text-decoration: none;
        }

        &.active {
            background-color: #eff1f6;
            font-weight: 700;
        }
    }
}

.full-section {
    background-color: #fafbfd;

    @media (max-width: 768px) {
        min-height: initial;
    }
}
</style>
